<template lang="pug">
  b-container(fluid)
    b-row
      b-col(cols="12")
        h3.mb-2 Actividad
        p.text-info Última actualización: {{ lastUpdate ? $moment(lastUpdate).format("DD MMMM YYYY, HH:mm:ss A") : 'No se ha actualizado' }}

      b-col(cols="12")
        b-tabs(v-model="tabIndex")
          b-tab(title="Registros", lazy)
            registros
          b-tab(title="Toda la actividad", lazy)
            activity
</template>

<script>
import Activity from '@/views/activity/Activity.vue'
import Registros from '@/views/activity/Registros.vue'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState({
      activityLastUpdate: state => state.activityLastUpdate,
      registrosLastUpdate: state => state.registrosLastUpdate
    }),
    lastUpdate () {
      if (this.tabIndex == 0) {
        return this.registrosLastUpdate
      } else {
        return this.activityLastUpdate
      }
    }
  },
  components: {
    Activity,
    Registros
  }
}
</script>

<style lang="scss" scoped>

</style>