<template lang="pug">
div
  b-table(
    :items="activities",
    :fields="fields",
    responsive,
    hover,
    no-border-collapse,
    @row-clicked="openRow",
    show-empty
  )
    template(#empty)
      .text-center No hay datos para mostrar
    template(#cell(event)="{item}")
      span {{ getEventName(item.event) }}
    template(#cell(metadata)="{item}")
      span.text-secondary(v-if="!Object.keys(item.metadata).length") No hay más información
      div(v-else)
        b-form-row
          b-col(cols="auto", v-viewer="{toolbar: false, navbar: false, movable: false, title: false, rotatable: false, scalable: false}")
            b-img(:src="item.metadata.selfie_img", v-if="item.metadata.selfie_img", width="70", height="100", rounded, style="object-fit:cover;")
          b-col
            b-badge.text-capitalize(:variant="getBadgeColor(item.metadata.status)", pill, v-if="item.metadata.status") {{ item.metadata.status }}
            p.mb-0.text-capitalize(v-if="item.metadata.tipo_registro") {{ item.metadata.tipo_registro }}
            p.mb-0.text-capitalize(v-if="item.metadata.registro_id")
              b-link(:to="{name: 'Registro', params: {registro_id: item.metadata.registro_id} }") Ver registro
            p.mb-0(v-if="item.metadata.first_name || item.metadata.last_name") {{ item.metadata.first_name }} {{ item.metadata.last_name }}
            p.mb-0.text-secondary(v-if="item.metadata.email") {{ item.metadata.email }}
    template(#cell(updatedAt)="{item}")
      span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
  .d-flex.align-items-center.mb-3
    b-pagination.mb-0(
      v-model="paginationPage",
      :total-rows="paginationTotal",
      :per-page="paginationLimit",
      @input="getActivities"
    )
    b-spinner.ml-3(variant="primary", small, v-if="loadingData")
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const activityController = createNamespacedHelpers("activity");

export default {
  data() {
    return {
      activityModal: false,
      fields: [
        {
          key: "activity_id",
          label: "Id",
        },
        {
          key: "event",
          label: "Evento",
        },
        {
          key: "metadata",
          label: "Info",
        },
        {
          key: "updatedAt",
          label: "Fecha",
        },
      ],
      timeout: null,
      shouldReload: true
    };
  },
  computed: {
    ...activityController.mapState({
      activities: (state) => state.activities,
    }),
  },
  created () {
    this.paginationLimit = 15
  },
  methods: {
    ...mapActions(["activity/all"]),
    getActivities() {
      clearTimeout(this.timeout);

      this.loadingData = true;
      this["activity/all"]({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit,
        },
        callback: (res) => {
          this.$store.commit('updateActivityLastUpdate');
          this.loadingData = false;
          if (this.shouldReload)
            this.timeout = setTimeout(() => { this.getActivities() }, 1000 * 3)

          if (res.success) {
            this.paginationTotal = res.total;
            this.paginationHasNextPage = res.hasNextPage;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo actividad. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    openRow(item) {
      
    },
    getBadgeColor (status) {
      switch ((status || '').toLowerCase()) {
        case 'entrada':
          return 'success';
          break;
        case 'salida':
          return 'danger';
          break;
        default:
          return 'secondary'
          break;
      }
    },
    getEventName (event) {
      switch (event) {
        case 'login':
        case 'logout':
          return 'Cambio de sesión'
          break;
        case 'checkout':
          return 'Salida de visita'
          break;
        default:
          return event;
      }
    }
  },
  mounted() {
    this.getActivities();
  },
  beforeDestroy () {
    this.shouldReload = false;
    clearTimeout(this.timeout);
  }
};
</script>

<style>
</style>