<template lang="pug">
div
  .text-right.my-2
    b-button(variant='warning' size='sm' @click='sendReport' :disabled='sending') Enviar reporte
  b-table(
    :items="registros",
    :fields="fields",
    responsive,
    hover,
    no-border-collapse,
    @row-clicked="openRow",
    show-empty,
    :tbody-tr-class="rowClass"
  )
    template(#cell(id)="{item}")
      span.text-sm {{ item.registro_id }}
    template(#empty)
      .text-center No hay datos para mostrar
    template(#cell(tipo_registro)="{item}")
      b-badge.mr-2(v-if='item.tipo_registro == "perfil"' pill variant='primary') {{ item.tipo_registro.capitalize() }}
      span.mr-2(v-else) {{ (item.tipo_registro || '-').capitalize() }}
      b-badge(small :variant='item.tipo_ingreso == "proveedor" ? "warning" : "secondary"' v-if='item.tipo_ingreso == "proveedor"') {{ item.tipo_ingreso.toUpperCase() }}
    template(#cell(status)="{item}")
      b-badge(:variant="getBadgeColor(item.status)", pill) {{ (item.status || 'Sin QR').capitalize() }}
    template(#cell(casa_id)="{item}")
      span {{ casasMap[item.casa_id] ? casasMap[item.casa_id].name : '- - -' }}
    template(#cell(cliente_id)="{item}")
      span {{ clientesMap[item.cliente_id] ? clientesMap[item.cliente_id].name : '- - -' }}
    template(#cell(updatedAt)="{item}")
      span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
    template(#cell(identity_img)="{item}")
      b-img(v-if="item.identity_img", :src="item.identity_img", width="100", height="100", rounded, style="object-fit:cover;")
      .text-center(v-else)
        x-circle-icon.text-danger
    template(#cell(selfie_img)="{item}")
      b-img(v-if="item.selfie_img", :src="item.selfie_img", width="100", height="100", rounded, style="object-fit:cover;")
      .text-center(v-else)
        x-circle-icon.text-danger
    template(#cell(car_img)="{item}")
      .text-center(v-if="item.tipo_registro == 'carro'")
        b-img(v-if="item.car_img", :src="item.car_img", width="100", height="100", rounded, style="object-fit:cover;")
        .text-center(v-else)
          x-circle-icon.text-danger
      .text-center(v-else)
        minus-icon.text-secondary
  .d-flex.align-items-center.mb-3
    b-pagination.mb-0(
      v-model="paginationPage",
      :total-rows="paginationTotal",
      :per-page="paginationLimit",
      @input="getActivities"
    )
    b-spinner.ml-3(variant="primary", small, v-if="loadingData")
</template>

<script>
import { createNamespacedHelpers, mapActions } from "vuex";
const registroController = createNamespacedHelpers("registro");
const clienteController = createNamespacedHelpers("cliente");
const casaController = createNamespacedHelpers("casa");

import axios from 'axios'

export default {
  data() {
    return {
      registros: [],
      sending: false,
      to: null,
      registroModal: false,
      fields: [
        'ID',
        'status',
        {
          key: "tipo_registro",
          label: "Tipo",
        },
        {
          key: 'cliente_id',
          label: 'Cliente'
        },
        {
          key: "casa_id",
          label: "Casa",
        },
        {
          key: 'identity_img',
          label: 'Identificación'
        },
        {
          key: 'selfie_img',
          label: 'Foto persona'
        },
        {
          key: 'car_img',
          label: 'Foto auto'
        },
        {
          key: "updatedAt",
          label: "Fecha",
        },
      ],
      timeout: null,
      shouldReload: true,
      clientesMap: {},
      casasMap: {}
    };
  },
  computed: {
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
    ...casaController.mapState({
      casas: (state) => state.casas,
    }),
  },
  created () {
    this.paginationLimit = 15
  },
  methods: {
    ...mapActions(["registro/all", 'cliente/all', 'casa/all']),
    getActivities() {
      clearTimeout(this.timeout);

      this.loadingData = true;
      this["registro/all"]({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit,
        },
        callback: (res) => {
          if (this.shouldReload)
            this.timeout = setTimeout(() => { this.getActivities() }, 1000 * 3)

          this.$store.commit('updateRegistrosLastUpdate');
          this.loadingData = false;

          if (res.success) {
            this.paginationTotal = res.total;
            this.paginationHasNextPage = res.hasNextPage;
            this.registros = res.resource;
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo actividad. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    openRow(item) {
      this.$router.push({name: 'Registro', params: {registro_id: item.registro_id} })
    },
    getClientes() {
      return new Promise(resolve => {
        this["cliente/all"]({
          params: {
            page: 1,
            limit: 10000,
          },
          callback: (res) => {
            if (res.success) {
              for (const c of this.clientes) {
                this.clientesMap[c.cliente_id] = c
              }
            } else {
              this.$bvToast.toast(
                `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }

            resolve()
          },
        });
      })
    },
    getBadgeColor (status) {
      switch ((status || '').toLowerCase()) {
        case 'entrada':
          return 'success';
          break;
        case 'salida':
          return 'danger';
          break;
        case 'invalido':
          return 'warning';
          break;
        default:
          return 'secondary'
          break;
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 'invalido') return 'table-danger'
    },
    getCasas() {
      return new Promise(resolve => {
        this["casa/all"]({
          params: {
            page: 1,
            limit: 10000,
          },
          callback: (res) => {
            if (res.success) {
              for (const c of this.casas) {
                this.casasMap[c.casa_id] = c
              }
            } else {
              this.$bvToast.toast(
                `Ocurrió un error obteniendo las casas. Por favor intenta de nuevo.`,
                {
                  title: "Ocurrió un error",
                  variant: "danger",
                }
              );
            }

            resolve()
          },
        });
      })
    },
    async getData () {
      await this.getClientes()
      await this.getCasas()
      this.getActivities()
    },
    async sendReport () {
      this.sending = true
      const body = {
        registros: this.registros.map(r => {
          const Casa = this.casas.find(c => c.casa_id == r.casa_id)
          const Cliente = this.clientes.find(c => c.cliente_id == r.cliente_id)

          return {
            ...r,
            Casa_map: this.casasMap[r.casa_id],
            Cliente_map: this.clientesMap[r.cliente_id],
            Casa_original: Casa,
            Cliente_original: Cliente
          }
        })
      }

      const res = await this.axios.post('https://init-central.herokuapp.com/qr/log', body)
      if (res.data && res.data.success) {
        this.$bvToast.toast(
          `El reporte se envió con éxito. Evita enviar múltiples reportes en un periodo de tiempo corto.`,
          {
            title: "Reporte enviado",
            variant: "success",
          }
        );

        setTimeout(() => {
          this.sending = false
        }, 5000)
      } else {
        this.$bvToast.toast(
          `Ocurrió un error enviando reporte. Intenta de nuevo o reporta la falla.`,
          {
            title: "Ocurrió un error",
            variant: "danger",
          }
        );

        this.sending = false
      }
    }
  },
  mounted() {
    this.getData();
  },
  beforeDestroy () {
    this.shouldReload = false;
    clearTimeout(this.timeout);
  }
};
</script>

<style>
</style>