var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right my-2"},[_c('b-button',{attrs:{"variant":"warning","size":"sm","disabled":_vm.sending},on:{"click":_vm.sendReport}},[_vm._v("Enviar reporte")])],1),_c('b-table',{attrs:{"items":_vm.registros,"fields":_vm.fields,"responsive":"","hover":"","no-border-collapse":"","show-empty":"","tbody-tr-class":_vm.rowClass},on:{"row-clicked":_vm.openRow},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(item.registro_id))])]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(tipo_registro)",fn:function(ref){
var item = ref.item;
return [(item.tipo_registro == "perfil")?_c('b-badge',{staticClass:"mr-2",attrs:{"pill":"","variant":"primary"}},[_vm._v(_vm._s(item.tipo_registro.capitalize()))]):_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s((item.tipo_registro || '-').capitalize()))]),(item.tipo_ingreso == "proveedor")?_c('b-badge',{attrs:{"small":"","variant":item.tipo_ingreso == "proveedor" ? "warning" : "secondary"}},[_vm._v(_vm._s(item.tipo_ingreso.toUpperCase()))]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.getBadgeColor(item.status),"pill":""}},[_vm._v(_vm._s((item.status || 'Sin QR').capitalize()))])]}},{key:"cell(casa_id)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.casasMap[item.casa_id] ? _vm.casasMap[item.casa_id].name : '- - -'))])]}},{key:"cell(cliente_id)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.clientesMap[item.cliente_id] ? _vm.clientesMap[item.cliente_id].name : '- - -'))])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(identity_img)",fn:function(ref){
var item = ref.item;
return [(item.identity_img)?_c('b-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.identity_img,"width":"100","height":"100","rounded":""}}):_c('div',{staticClass:"text-center"},[_c('x-circle-icon',{staticClass:"text-danger"})],1)]}},{key:"cell(selfie_img)",fn:function(ref){
var item = ref.item;
return [(item.selfie_img)?_c('b-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.selfie_img,"width":"100","height":"100","rounded":""}}):_c('div',{staticClass:"text-center"},[_c('x-circle-icon',{staticClass:"text-danger"})],1)]}},{key:"cell(car_img)",fn:function(ref){
var item = ref.item;
return [(item.tipo_registro == 'carro')?_c('div',{staticClass:"text-center"},[(item.car_img)?_c('b-img',{staticStyle:{"object-fit":"cover"},attrs:{"src":item.car_img,"width":"100","height":"100","rounded":""}}):_c('div',{staticClass:"text-center"},[_c('x-circle-icon',{staticClass:"text-danger"})],1)],1):_c('div',{staticClass:"text-center"},[_c('minus-icon',{staticClass:"text-secondary"})],1)]}}])}),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.paginationTotal,"per-page":_vm.paginationLimit},on:{"input":_vm.getActivities},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}}),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3",attrs:{"variant":"primary","small":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }